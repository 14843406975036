import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/authentication/authentication.module').then(a => a.AuthenticationModule)},
  {path:'home',loadChildren:() => import('./modules/home/home.module').then(a=> a.HomeModule)  },
  { path: 'Admin', loadChildren: () => import('./modules/Admin/admin.module').then(m => m.AdminModule) },
  { path: 'external', loadChildren: () => import('./modules/external/external.module').then(m => m.ExternalModule) },
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }