import { Component, OnInit, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-message-service',
  templateUrl: './message-service.component.html',
  styleUrls: ['./message-service.component.scss']
})
export class MessageService {
  private subject = new Subject<any>();
 

  ngOnInit(): void {
  }
  sendMessage(message: string) {
    this.subject.next({ message });
  }
  ShopIdMessage(msg:string)
  {
    this.subject.next({msg});
  }
  getShopIdMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  clearMessage() {
    // this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
