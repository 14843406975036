import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CharityVanService {

  constructor( private http: HttpClient) { }
  get(url) {
    // // // console.log(url);
    return this.http.get(url);
  }
  post(url, data) {
    // // console.log(url);
    return this.http.post(url, data);
  }
  postwithheader(url,data)
  {
    // // console.log(url);
    return this.http.post(url, data,{headers:this.getUploadOptions()});
  } 
  put(url, data) {
    return this.http.put(url, data);
  }
  putwithheader(url, data)
  {
    // // console.log(url);
    return this.http.post(url, data,{headers:this.getUploadOptions()});
  }
  getUploadOptions = (): HttpHeaders => {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json');
    headers.delete('Content-Type');
    return headers;
  }
  // setUserDetails(userDetails)
  // {
  //    localStorage.setItem('UserDetails',JSON.stringify(userDetails));
  // }
  // getUserDetails()
  // {
  //   return localStorage.getItem('UserDetails')!=null?JSON.parse(localStorage.getItem('UserDetails')):{};
  // }
  // setToken(token)
  // {
  //   sessionStorage.setItem('token',JSON.stringify(token));
  // }
  // getToken()
  // {
  //   return sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')) : "";
  // }
}