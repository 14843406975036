import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpHeaders, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from "rxjs/operators";
import { environment } from '../environments/environment';
import { CVHttpService } from './modules/shared/cv-http.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService } from '@syncfusion/ej2-angular-schedule';
import { ToastrModule } from 'ngx-toastr';
// import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { MessageService } from './modules/message-service/message-service.component';
import { SortableColumnComponent } from './modules/sortable-column/sortable-column.component';
import { SortableTableDirective } from './modules/sortable-column/sortable-table.directive';
import { ScheduleJobComponent } from './modules/createbookings/schedule-job/schedule-job.component';
import { CharityVanService } from './app.service';
import { CalendarModule } from 'primeng/calendar';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  tokenId: any;
  constructor(private CVHttpService: CVHttpService, private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = localStorage.getItem('access_token');
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      });

      if (req.url.includes('api.getaddress.io')){
        const authReq = req.clone({
          url: req.url,
        });
        return next.handle(authReq).pipe(finalize(() =>
          setTimeout(() => {
          }, 500)
        ),
          error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this.router.navigate(['/errormessage']);
            }
            return error;
          });
      }else{
        if(req.method != 'PUT')
        {
          const authReq = req.clone({
            url: environment.apiURL + req.url,
            headers: headers
          });
          return next.handle(authReq).pipe(finalize(() =>
            setTimeout(() => {
            }, 500)
          ),
            error => {
              if (error instanceof HttpErrorResponse && error.status === 401) {
                this.router.navigate(['/errormessage']);
              }
              return error;
            });
        }
        else
        {
          const authHeader = new HttpHeaders(
            {
              'Authorization': "Bearer " + authToken
            });
          const authReq = req.clone({
            url: environment.apiURL + req.url,
            headers: authHeader
          });
          return next.handle(authReq).pipe(finalize(() =>
            setTimeout(() => {
            }, 500)
          ),
            error => {
              if (error instanceof HttpErrorResponse && error.status === 401) {
                this.router.navigate(['/errormessage']);
              }
              return error;
            });
        }
      }
      }
    
}

@NgModule({
  declarations: [
    AppComponent,
    MessageService
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxDatatableModule,
    // DateInputsModule,
    CalendarModule,
    
    // BackButtonDisableModule.forRoot({
    //   preserveScrollPosition: true
    // }),
    ToastrModule.forRoot({ preventDuplicates: true, closeButton: true, progressBar: true, positionClass: 'toast-top-right' }), 
   
  ],
  
  providers: [CharityVanService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService,
    CVHttpService,DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true }, { provide: Window, useValue: window }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }