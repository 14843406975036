import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CVHttpService {
  activeSpace = new Subject<string>();
  constructor(private http: HttpClient) { 

  }
  setUrl(url:string){
    this.activeSpace.next(url);
  }
  get(url) {
    // // console.log("Get request to " + url);
    return this.http.get(url);
  }
  post(url, data) {
    // // console.log("Post request to " + url);
    return this.http.post(url, data);
  }
  postwithheader(url,data)
  {
    // // // console.log(url);
    return this.http.post(url, data,{headers:this.getUploadOptions()});
  }
  put(url, data) {
    // // console.log("Put request to " + url);
    return this.http.put(url, data);
  }
  putwithheader(url, data)
  {
    // // console.log(url);
    return this.http.put(url, data,{headers:this.getUploadOptions()});
  }
  
  getUploadOptions = (): HttpHeaders => {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json');
    headers.delete('Content-Type');
    return headers;
  }
}
