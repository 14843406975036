import { Component, OnInit } from '@angular/core';
// import 'src/assets/scripts/accessible.js'
declare var acsbJS: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CharityVan';

  constructor(){
    
  }
  ngOnInit(): void {
  //   acsbJS.init({
  //     statementLink: '',
  //     footerHtml: '',
  //     hideMobile: false,
  //     hideTrigger: false,
  //     disableBgProcess: false,
  //     language: 'en',
  //     position: 'right',
  //     leadColor: '#5BC3BC',
  //     triggerColor: '#5BC3BC',
  //     triggerRadius: '50%',
  //     triggerPositionX: 'right',
  //     triggerPositionY: 'bottom',
  //     triggerIcon: 'people',
  //     triggerSize: 'bottom',
  //     triggerOffsetX: 20,
  //     triggerOffsetY: 20,
  //     mobile: {
  //         triggerSize: 'small',
  //         triggerPositionX: 'right',
  //         triggerPositionY: 'bottom',
  //         triggerOffsetX: 10,
  //         triggerOffsetY: 10,
  //         triggerRadius: '20'
  //     }
  // });
  }
}
